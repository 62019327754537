import React from 'react';

const Bullet = () => {
	return (
		<svg width="47" height="44" viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path d="M20.0019 4L37.3241 13.9999V33.9997L20.0019 43.9996L2.67975 33.9997V13.9999L20.0019 4Z" fill="#041A2F"/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
				<path d="M9.55585 10.3882L26.378 0.676945L43.2002 10.3882V29.8106L26.378 39.5219L9.55585 29.8106V10.3882Z" stroke="#041A2F"/>
			</g>
		</svg>
	);
};

export default Bullet;
