import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MarkdownRenderer from '../../components/MarkdownRenderer';
import InView from '../InView';
import Bullet from './Bullet';

import Section from '../Section';

const BulletPoints = ( { moduleId, header, bulletPoints, backgroundColor, textColor } ) => {
	const [ defHead, setDefHead ] = useState( {
		title: 'Bullet List',
		titleColor: 'primary-100',
		width: true,
		copy: {
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.',
			width: true
		}
	} );

	useEffect( () => {
		setDefHead( { ...defHead, ...header } );
	}, [ header ] );

	return (
		<Section id={ moduleId || '' } className={`${ backgroundColor ? `bg-${ backgroundColor }` : '' } text-${ textColor || 'primary-black' }`}>
			<div className="bulletPoints">
				<InView y={2} scale={0.99} className={ !defHead.copy.width ? 'halfWidth' : 'fullWidth' }>
					<h2 className={`text-${ defHead.titleColor }`}>
						{ defHead.title }
					</h2>
					<MarkdownRenderer copy={ defHead.copy.text } />
				</InView>
				<InView y={-2} scale={0.999} className="bulletPointContainer">
					{bulletPoints.map( ( bp, i ) => (
						<div key={ i }>
							<Bullet />
							{ bp.bullet && (
								<>
									<h4>{ bp.bullet.heading || `Bullet ${ i + 1 }` }</h4>
									<div className="copyWrapper">
										<MarkdownRenderer copy={ bp.bullet.copy || '' } />
									</div>
								</>
							)}
						</div>
					) )}
				</InView>
			</div>
		</Section>
	);
};

BulletPoints.defaultProps = {
	header: {
		title: 'Bullet List',
		titleColor: 'primary-100',
		width: true,
		copy: {
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.',
			width: true
		}
	},
	bulletPoints: [
		{
			bullet: {
				heading: 'Bullet One',
				copy: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.'
			}
		},
		{
			bullet: {
				heading: 'Bullet Two',
				copy: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.'
			}
		},
		{
			bullet: {
				heading: 'Bullet Three',
				copy: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.'
			}
		},
		{
			bullet: {
				heading: 'Bullet Four',
				copy: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.'
			}
		}
	],
	backgroundColor: '',
	textColor: ''
};

BulletPoints.propTypes = {
	moduleId: PropTypes.string,
	header: PropTypes.object,
	bulletPoints: PropTypes.array,
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string
};

export default BulletPoints;
