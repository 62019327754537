import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Img from 'gatsby-image/withIEPolyfill';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import ButtonGroup from '../Button/ButtonGroup';

import { toSlug } from '../../utils/formatters';

const Wrapper = props => {
	const link = () => {
		let slug = '';

		if ( props.nav.staticLink ) {
			switch ( props.nav.staticLink ) {
				case 'Home':
					slug = '/';
					break;
				case 'Contact Page':
					slug = '/contact';
					break;
				case 'Contact Form':
					slug = '#form';
					break;
				default:
					break;
			}
		} else {
			slug = toSlug( props.nav.dynamicLink );
		}

		return slug;
	};

	return (
		<>
			{ props.variant !== 'navCard'
				? <article
					id={ props.moduleId || '' }
					className={ `col-${ props.grid } ${ props.expanded ? 'expanded' : '' } ${ 'cardWrapper' }` }
					onClick={ () => props.setExpanded() }
				>
					{ props.children }
				</article>
				: <Link
					id={ props.moduleId || '' }
					to={ link() } className={ `col-${ props.grid } cardWrapper` }
				>
					{ props.children }
				</Link> }
		</>
	);
};

Wrapper.propTypes = {
	moduleId: PropTypes.string,
	variant: PropTypes.string,
	children: PropTypes.node,
	grid: PropTypes.string,
	nav: PropTypes.object,
	setExpanded: PropTypes.func,
	expanded: PropTypes.bool
};

const Card = props => {
	const [ expanded, setExpanded ] = useState( false );
	const renderImg = useCallback( img => {
		if ( !img ) {
			return null;
		}

		if ( !img.childImageSharp ) {
			return <img src={ img.extension === 'svg' ? img.publicURL : img } alt={ props.heading } />;
		}

		return (
			<Img
				fluid={ img.childImageSharp.fluid }
				objectFit="cover"
				objectPosition="50% 50%"
				alt={ props.heading }
			/>
		);
	}, [ props.icon, props.img ] );

	const renderCopy = useCallback( copy => {
		switch ( copy.type ) {
			case 'text':
				return <MarkdownRenderer key={ copy.i } copy={ !expanded ? !props.img ? `${ copy.text.slice( 0, props.copy.length > 1 ? 129 : 200 ) }...` : '' : copy.text } />;
			case 'buttons':
				return (
					<div key={ copy.i } style={{ margin: '2rem 0 0 auto' }}>
						<ButtonGroup buttons={ copy.button || [] } />
					</div>
				);
			default:
				return null;
		}
	}, [ props.copy, expanded ] );

	return (
		<>
			<Wrapper
				{ ...props }
				moduleId={ props.moduleId }
				expanded={ expanded }
				setExpanded={ () => setExpanded( !expanded ) }
			>
				<div className={ `card ${ props.icon ? 'iconCard' : '' } ${ props.img ? 'imgCard' : '' }` }>
					<div className="contentWrapper">
						{ Boolean( props.img || props.icon ) && (
							<div className={ `${ props.icon ? 'icon' : 'img' }` }>
								{ props.icon && renderImg( props.icon ) }
								{ props.img && renderImg( props.img ) }
							</div>
						)}
						<div className="copyWrapper">
							<div>
								{ props.subHeading
							&& <p className="overline">{ props.subHeading }</p> }
								<h5 className="cardName" style={{ marginBottom: props.img && '0.5rem' }}>{ props.heading }</h5>
							</div>
							<div className="cardCopy">
								{ typeof props.copy !== 'string'
									? props.copy.map( ( ca, i ) => renderCopy( { ...ca, i } ) )
									: <MarkdownRenderer copy={ props.copy }/> }
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</>
	);
};

Card.defaultProps = {
	heading: 'Feature Card',
	// subHeading: 'Featured item',

	/* icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/2020_D%C3%ADa_de_La_Tierra_black_icon.svg/1920px-2020_D%C3%ADa_de_La_Tierra_black_icon.svg.png',
	   img: 'https://images.unsplash.com/photo-1583337130417-3346a1be7dee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80', */
	copy: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptates, nemo ea laborum sit beatae corporis temporibus rerum voluptatem officia officiis praesentium optio saepe nulla aspernatur a earum accusamus vero exercitationem.'
};

Card.propTypes = {
	moduleId: PropTypes.string,
	heading: PropTypes.string,
	subHeading: PropTypes.string,
	icon: PropTypes.object,
	img: PropTypes.any,
	copy: PropTypes.any
};

export default Card;
