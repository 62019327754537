import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import Img from 'gatsby-image/withIEPolyfill';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import DropDown, { activeSetter } from '../DropDown';

import ButtonGroup from '../Button/ButtonGroup';
import Section from '../Section';

import { featureVar } from '../../utils/framerVariants';

const Feature = ( {
	carousel,
	moduleId,
	heading,
	copy,
	img,
	imgBorder,
	cropped,
	variant,
	backgroundColor,
	backgroundImage,
	headerColor,
	textColor,
	border
} ) => {
	const [ active, setActive ] = useState( [] );
	const [ image, setImage ] = useState( null );

	const renderCopy = useCallback( copy => {
		switch ( copy.type ) {
			case 'text':
				return <MarkdownRenderer key={ copy.i } copy={copy.text || ''} />;
			case 'dropDown':
				return (
					<DropDown.Base key={ copy.i }>
						{ Boolean( copy.dropDownItem ) && copy.dropDownItem.map( ( item, i ) => (
							<DropDown.Section
								key={ i }
								active={ active.includes( item ) }
								setActive={ () => activeSetter( { active, setActive }, item ) }
								title={ item.title || '' }
							>
								<div>
									<h6 className="overline" style={{ marginBottom: '1rem' }}>{ item.heading }</h6>
									<MarkdownRenderer copy={ item.copy || '' } />
								</div>
							</DropDown.Section>
						) )}
					</DropDown.Base>
				);
			case 'buttons':
				return (
					<div key={ copy.i } style={{ margin: '3rem 0 0 auto' }}>
						<ButtonGroup buttons={ copy.button || [] } />
					</div>
				);
			default:
				return null;
		}
	}, [ active ] );

	useEffect( () => {
		if ( img ) {
			if ( img.childImageSharp ) {
				setImage( img.childImageSharp.fluid.src );
			} else if ( img.extension === 'svg' ) {
				setImage( img.publicURL );
			} else {
				setImage( img );
			}
		}
	}, [ img ] );

	return (
		<Section
			id={moduleId || ''}
			style={{
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				backgroundImage: backgroundImage ? `url(${ image ? image : '' })` : '',
				padding: carousel && 0
			}}
			className={ `${ backgroundImage ? 'backgroundImgFeature' : '' } bg-${ backgroundColor } ${ border ? 'border-primary-black' : '' }` }
		>
			<motion.div
				variants={ featureVar }
				initial="hidden"
				animate="visible"
				className="featureWrapper">
				{ !backgroundImage
				&& <h2
					className={`text-${ headerColor }`}
					style={ { marginTop: 0 } }
				>
					{ heading }
				</h2> }
				<article className={ `feature ${ variant }Feature ${ backgroundImage && 'overlay' }` }>
					{ !backgroundImage && Boolean( img ) && (
						<div
							className={ `imgContainer ${ cropped && 'croppedImg' }` }
						>
							{ img.childImageSharp ? (
								<Img
									fluid={ img.childImageSharp.fluid }
									objectFit="cover"
									objectPosition="50% 50%"
									alt={ heading }
									className={ `img ${ imgBorder ? 'border-primary-black' : '' }` }
								/>
							) : (
								<div
									className={ `img ${ imgBorder ? 'border-primary-black' : '' }` }
									style={{ backgroundImage: `url(${ img.extension === 'svg' ? img.publicURL : img })` }}
								/>
							) }
						</div>
					)}
					<div className={ `contentContainer  ${ backgroundImage && 'fullWidth' } text-${ textColor }` }>
						{ backgroundImage
						&& <h2
							className={`text-${ headerColor }`}
							style={ { marginTop: 0 } }
						>
							{ heading }
						</h2> }
						{ Boolean( copy ) && copy.map( ( c, i ) => renderCopy( { ...c, i } ) ) }
					</div>
				</article>
			</motion.div>
		</Section>
	);
};

Feature.defaultProps = {
	carousel: false,
	heading: 'Featured Content',
	copy: [
		{
			type: 'text',
			text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum accusantium, labore eos deleniti corporis rem libero sed at atque saepe! Voluptatibus minus ducimus maxime rerum deleniti ipsa dolor dignissimos nemo.'
		}
	],
	variant: 'left',
	// img: 'https://images.unsplash.com/photo-1586797290707-aa6d01d0dd77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
	cropped: true,
	imgBorder: false,
	backgroundColor: 'lightened-200',
	backgroundImage: false,
	headerColor: 'primary-100',
	textColor: 'primary-black',
	border: false
};

Feature.propTypes = {
	carousel: PropTypes.bool,
	heading: PropTypes.string,
	moduleId: PropTypes.string,
	copy: PropTypes.array,
	img: PropTypes.object,
	imgBorder: PropTypes.bool,
	cropped: PropTypes.bool,
	variant: PropTypes.string,
	backgroundColor: PropTypes.string,
	backgroundImage: PropTypes.bool,
	headerColor: PropTypes.string,
	textColor: PropTypes.string,
	border: PropTypes.bool
};

export default Feature;

